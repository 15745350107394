/*
  Input Component Style
*/
.stacked-input {
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.stacked-input input {
  background: transparent;
  color: #fff;
  border: 0;
  border-bottom: 1px solid #777;
  padding: 5px;
  text-align: left;
  outline: none;
  font-size: 14px;
  -webkit-transition: background 0.25s ease-in;
  -moz-transition: background 0.25s ease-in;
  -o-transition: background 0.25s ease-in;
  transition: background 0.25s ease-in;
}

.stacked-input label {
  color: #a9aaab;
  text-align: left;
}

.input-wrapper {
  margin: 10px;
}

.input-label {
  color: #a9aaab;
  text-align: left;
}

.input-label input {
  background: transparent;
  color: #fff;
  border: 0;
  border-bottom: 1px solid #fff;
  padding: 5px;
  text-align: center;
  outline: none;
  font-size: 14px;
  margin-left: 20px;
  -webkit-transition: background 0.25s ease-in;
  -moz-transition: background 0.25s ease-in;
  -o-transition: background 0.25s ease-in;
  transition: background 0.25s ease-in;
}

.input-label input:focus,
.stacked-input input:focus {
  border-bottom: 1px solid #fff;
}

.input-label input.error,
.stacked-input input.error {
  border-bottom: 1px solid #D51A2C;
  background: rgba(213, 26, 44, 0.25);
}

.input-label input[type='number']::-webkit-inner-spin-button,
.input-label input[type='number']::-webkit-outer-spin-button,
.stacked-input input[type='number']::-webkit-inner-spin-button,
.stacked-input input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
