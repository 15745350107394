.onboarding-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.fade-in {
  -webkit-transition: opacity 0.4s ease-in-out;
  -moz-transition: opacity 0.4s ease-in-out;
  -o-transition: opacity 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out;
}

.onboarding {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 60px;
  margin: 20px;
  background-color: #24313F;
  color: #f5f5f5;
  width: 100%;
  max-width: 1024px;
  height: 100%;
  max-height: 650px;
}

.onboarding-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.onboarding-title h1 {
  margin: 0;
}

.onboarding-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 15px;
  width: 100%;
}

.onboarding-content h3{
  text-align: center;
  margin-top: 0;
  margin-bottom: 20px;
}

.onboarding-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  min-height: 40px;
}

.onboarding .steps {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  align-self: center;
  margin-top: 45px;
}

.onboarding .steps .step {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid #3C4F61;
  color: #3C4F61;
  font-size: 20px;
  margin: 0 15px;
  position: relative;
  user-select: none;
  cursor: pointer;
}

.onboarding .steps .step:disabled {
  cursor: not-allowed;
}

.onboarding .steps .step:first-child {
  margin-left: 0;
}

.onboarding .steps .step:last-child {
  margin-right: 0;
}

.onboarding .steps .step:not(:last-child):after {
  content: "";
  position: absolute;
  left: 45px;
  width: 15px;
  height: 2px;
  background-color: #3C4F61;
}

.onboarding .steps .step.completed:not(:last-child):after {
  content: "";
  position: absolute;
  left: 45px;
  width: 15px;
  height: 2px;
  background-color: #76BD22;
}

.onboarding .steps .step.selected {
  border: 2px solid #76BD22;
  color: #76BD22;
}

.onboarding .steps .step.completed {
  border: none;
}