.connect-screen .message-box {
  width: 320px;
  background: #1e2a36;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.connect-screen .message-box.lobby {
  justify-content: center;
  align-items: center;
}

.connect-screen .message-box .show-id {
  color: #ffffff;
  font-size: 20px;
  display: none; /* remove after eliminating V1 */
}

.connect-screen .message-box .title {
  margin-top: 20px;
  font-size: 18px;
}

.connect-screen .message-box .name {
  margin-top: 5px;
  color: #3abb79;
  font-size: 14px;
  margin-bottom: 15px;
}

.connect-screen .message-box img {
  margin-top: 15px;
  width: 60%;
}

.connect-screen .message-box .text {
  width: 75%;
  color: #ffffff;
  font-size: 13px;
  text-align: center;
  margin-bottom: 15px;
}

.connect-screen .message-box .text.green {
  color: #3abb79;
}

.connect-screen .message-box .message-footer {
  background: #23313f;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 15px;
  height: 175px;
}

.connect-screen .message-box .message-footer .top {
  margin-top: 10px;
}

.connect-screen .message-box .message-footer .bottom {
  margin-bottom: 10px;
}

.connect-screen .message-box .message-footer .position {
  margin: 15px 0;
  background-color: #184337;
  color: #ffffff;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 20px;
}

.horizontal-message-box {
  width: 500px;
  background: #1e2a36;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.horizontal-message-box .header {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.horizontal-message-box .header img {
  width: 200px;
}

.horizontal-message-box .content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 220px;
  background-color: #26303e;
  margin-top: 30px;
}

.horizontal-message-box .content .left {
  flex: 1;
  margin: 15px;
}

.horizontal-message-box .content .left .text {
  color: #ffffff;
  font-size: 18px;
}

.horizontal-message-box .content .left button {
  color: #ffffff;
  background-color: #479859;
  font-size: 18px;
  border: none;
  text-decoration: none;
  padding: 10px 30px;
  border-radius: 5px;
  margin-top: 15px;
  outline: none;
}

.horizontal-message-box .content .left button.cancel {
  color: #ffffff;
  background-color: #4e6379;
  font-size: 18px;
  border: none;
  text-decoration: none;
  padding: 10px 30px;
  border-radius: 5px;
  margin-top: 15px;
  outline: none;
}

.horizontal-message-box .content .left button:active {
  position: relative;
  top: 2px;
  left: 2px;
}

.horizontal-message-box .content .right {
  flex: 1;
  margin: 15px;
}

.horizontal-message-box .content .right img {
  width: 180px;
}

.vertical-message-box {
  width: 500px;
  background: #1e2a36;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.vertical-message-box .header {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.vertical-message-box .header img {
  width: 200px;
}

.vertical-message-box .content {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #26303e;
  margin-top: 30px;
}

.vertical-message-box .content .left {
  flex: 1;
  margin: 0 15px 30px 15px;
}

.vertical-message-box .content .left .text {
  color: #ffffff;
  font-size: 18px;
  margin: 0 55px;
}

.vertical-message-box .content .left .text ol {
  padding-left: 28px;
  margin-top: 14px;
  font-size: 16px;
}

.vertical-message-box .content .left .text ol li {
  margin-bottom: 10px;
}

.vertical-message-box .content .left button {
  color: #ffffff;
  background-color: #479859;
  font-size: 18px;
  border: none;
  text-decoration: none;
  padding: 10px 30px;
  border-radius: 5px;
  margin-top: 15px;
  outline: none;
}

.vertical-message-box .content .left button:active {
  position: relative;
  top: 2px;
  left: 2px;
}

.vertical-message-box .content .right {
  flex: 1;
}

.vertical-message-box .content .right img {
  width: 320px;
}
