html,
body,
#root,
.App {
  width: 100%;
  height: 100%;
  background-color: #181f26;
  overflow: hidden;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.swal-overlay {
  background-color: rgba(0, 0, 0, 0.6);
}

.swal-modal {
  background-color: #1e2a35;
  border: 2px solid #4e6379;
}

.swal-button--confirm {
  background-color: #2b763a;
}

.swal-button--confirm:not([disabled]):hover {
  background-color: #2b763a;
}

.swal-title {
  color: #a9aaab;
}

.swal-text {
  color: #a9aaab;
}

.swal-modal.swal-custom-content {
  width: 560px;
  background-color: #202a36;
  border: none;
}

.swal-modal.swal-custom-content-ipad {
  width: 560px;
  background-color: #202a36;
  border: none;
  margin-left: 24%;
}

.swal-modal.swal-break {
  width: 560px;
  background-color: #202a36;
  border: none;
  margin-bottom: 50%;
}

.swal-modal.swal-break .swal-title {
  font-size: 20px;
  margin-bottom: 15px;
  margin-top: 20px;
  padding: 0;
}

.swal-modal.swal-break .swal-text:last-child {
  margin-bottom: 35px;
}

.swal-modal.swal-break-ipad {
  width: 560px;
  background-color: #202a36;
  border: none;
  margin-bottom: 50%;
}

.swal-modal.swal-break-ipad .swal-title {
  font-size: 20px;
  margin-bottom: 15px;
  margin-top: 20px;
  padding: 0;
}

.swal-modal.swal-break-ipad .swal-text:last-child {
  margin-bottom: 35px;
}

.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}
.lds-spinner div {
  transform-origin: 20px 20px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: ' ';
  display: block;
  position: absolute;
  top: -6px;
  left: 18px;
  width: 4px;
  height: 14px;
  border-radius: 18%;
  background: #fff;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
}
.custom-scrollbar::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  background-color: transparent;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #4e6379;
  outline: 1px solid #4e6379;
  border-radius: 3px;
}

.link {
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
}

.login-message-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.login-message-container .message-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;
  text-align: center;
  font-size: 13px;
  color: #999;
}

.landing-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  color: #fff;
}

.landing-page .flex-columns {
  display: flex;
  flex-direction: row;
}

.landing-page .flex-columns .logo-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin-right: 60px;
}

.landing-page .flex-columns .content {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.landing-page .flex-columns .content h1 {
  font-size: 45px;
  margin-bottom: 10px;
}

.landing-page .flex-columns .content h3 {
  width: 320px;
  text-transform: uppercase;
}

.landing-page .flex-columns .content p {
  width: 480px;
}

.landing-page .flex-columns .content .button-container {
  margin-top: 30px;
}